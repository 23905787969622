import React, { useState, useEffect } from "react";
import BoxChart from "../../components/generals/boxCharts";
import { get_data } from "../../actions/index";
import Header from "../../components/generals/header";
import Line from "../../components/generals/charts/line";
import "../../css/Safeweb/home.css";
import Wanted from "../../components/SafeWeb/Wanted";
import { currentAndPreviousDate } from "../../components/generals/charts/utils/DatesFormats.js";

export default function SafeWeb() {
  const [blackList, setBlackList] = useState([]);
  const [whiteList, setWhiteList] = useState([]);
  const [queryList, setQueryList] = useState([]);
  const [searchLast, setSearchLast] = useState([]);
  const [searchMost, setSearchMost] = useState([]);

  let date = currentAndPreviousDate(6, "-");
  let day = new Date(date[1]).toISOString().split("T")[0];
  
  const getSafeWebLast = async () => {
    let last = await get_data("safeweb/home/search", "searchLast");
    if(last)setSearchLast(last.data);
  };

  const getSafeWebMost = async () => {
    let most = await get_data("safeweb/home/search", "searchMost");
    if(most)setSearchMost(most.data);
  };

  const getBlackList = async (date) => {
    let black = await get_data("safeweb/home/blackList", date);
    if (black)setBlackList(black.data);
  };

  const getWhiteList = async (date) => {
    let white = await get_data("safeweb/home/whiteList", date);
    if (white)setWhiteList(white.data);
  };

  const getQueryList = async (date) => {
    let query = await get_data("safeweb/home/queryList", date);
    if (query)setQueryList(query.data);
  };

  useEffect(() => {
    getSafeWebLast();
    getSafeWebMost();
    getBlackList(day);
    getWhiteList(day);
    getQueryList(day);
  }, [day]);

    return (
      <div className="containerHeight content-wrapper">
        <Header title="SafeWeb" />
        <div className="headerHome">
          <div className="SFWBList">
            <BoxChart
              margin="auto"
              width="100%"
              height="100%"
              heightBody="80%"
              title="Listas Negras"
              icon="pi pi-bookmark-fill"
            >
              {blackList && blackList.length === 0 ? (
                <p>Sin data a mostrar</p>
              ) : (
                <Line data={blackList} ubication="SfWb-home" />
              )}
            </BoxChart>
            <BoxChart
              margin="auto"
              width="100%"
              height="100%"
              heightBody="80%"
              title="Listas Blancas"
              icon="pi pi-bookmark"
            >
              {whiteList && whiteList.length === 0 ? (
                <p>Sin data a mostrar</p>
              ) : (
                <Line data={whiteList} ubication="SfWb-home" />
              )}
            </BoxChart>
          </div>
          <div className="SFWBList2">
            <BoxChart
              margin="auto"
              width="100%"
              height="100%"
              heightBody="90%"
              heightHeader="10%"
              title="Últimos Buscados"
              icon="pi pi-history"
            >
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: "10px",
                }}
              >
                <Wanted data={{last: searchLast, most: searchMost}} />
              </div>
            </BoxChart>
          </div>
        </div>

        <div className="SFWBQuery">
          <BoxChart
            margin="auto"
            width="100%"
            height="30rem"
            heightHeader="20%"
            heightBody="80%"
            title="Visitas"
            icon="pi pi-tags"
          >
            {queryList && queryList.length === 0 ? (
              <p>Sin data a mostrar</p>
            ) : (
              <Line data={queryList} ubication="SfWb-home" />
            )}
          </BoxChart>
        </div>
      </div>
    );
}
