import React, { useState, useRef } from "react";
import Header from "../../components/generals/header";
import DataTableLazyApps from "../../components/generals/datatables/dataTableLazyApps";
import { put_data } from "../../actions/index";
import Button from "react-bootstrap/Button";
import { Toast } from "primereact/toast";
import styles from "../../css/applications/Apps.module.css";

export default function Applications() {
  const toast = useRef(null);
  const [reload, setReload] = useState(false);

  //Categorias
  let categorys = [
    { name: "No Permitidas", id: 0 },
    { name: "Sistema", id: 1 },
    { name: "Educativas", id: 2 },
    { name: "No Educativas", id: 3 },
    { name: "Productividad", id: 4 },
    { name: "Internet", id: 5 },
    { name: "Ocio", id: 6 },
    { name: "Otras", id: 7 },
    { name: "Sin Asignar", id: 8 }
  ];

  //aplicaciones por actualizar
  let appsToPut = [];

  const getApp = async (data) => { 
    let appFound;
    let app = data;

    if(appsToPut.length === 0){
      appsToPut.push(app);
    } else {
      appFound = appsToPut.find((item) => item.id === app.id);
      if (appFound === undefined) {
        appsToPut.push(app);
      } else {
        appsToPut.forEach((item) =>
          item.id === appFound.id ? (item.groupApp = app.groupApp) : null
        );
      }
    }
  };

  function showToast(type, value, message) {
    if (value) {
      toast.current.show({
        severity: type,
        summary: value,
        detail: message,
        life: 3000,
      });
    } else {
      toast.current.show({
        severity: type,
        summary: value,
        detail: message,
        life: 3000,
      });
    }
  };

  const getCategory = (value) => {
    switch (value) {
      case 'No Permitidas':
        return 0;
      case 'Sistema':
        return 1;
      case 'Educativas':
        return 2;
      case 'No Educativas':
        return 3;
      case 'Productividad':
        return 4;
      case 'Internet':
        return 5;
      case 'Ocio':
        return 6;
      case 'Otras':
        return 7;
      case 'Sin Asignar':
        return null;
      default:
        return null;
    }
  };

  const putApps = async (data) => {
    let response;
    if (data.length === 0) {
      showToast("info", "Aviso", "No se realizaron cambios");
      return;
    } else {
      data.forEach(element => {
        element.groupApp = getCategory(element.groupApp);
      });
      response = await put_data("applications", data);

      if (response.status === 200) {
        setReload(true);
        showToast("success", "Éxito", "Modificación exitosa");
      } else {
        showToast("error", "Error", "Modificación fallida");
      }
    }
    
    data = [];
  };

  return(
    <div className="content-wrapper containerHeight">
      <Header title="Aplicaciones" />
      <Toast 
        ref={toast}
        className={styles.toast}
      />
      <div className={styles.headerManager}>
        <Button
          onClick={() => putApps(appsToPut)}
          variant="dark"
          className={styles.button}
          id="button-update-apps"
        >
          <i className="fas fa-save" style={{ marginRight: "0.5rem" }}></i>
            Guardar
        </Button>
      </div>
      <DataTableLazyApps 
        categorys={categorys}
        getApp={getApp}
        getCategory={getCategory}
        reload={reload}
        setReload={setReload}
      />
    </div>
  );
}
