import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Tag } from 'primereact/tag';
import { get_data } from "../../../actions/index";
import $ from "jquery";
import "../../../css/generals/dataTable.css";

export default function DataTableLazyApps({categorys, getApp, getCategory, reload, setReload}){
    // console.log(categorys);
    const dt = useRef(null);
    const it = useRef(null);

    //*estados de componentes
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(10);
    const [apps, setApps] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [selectedFilter, setSelectedFilter] = useState("app");
    const [category, setCategory] = useState(null);
    const [lazyParams, setLazyParams] = useState({
      first: 0,
      rows: 10,
      page: 1,
      sortField: null,
      sortOrder: null,
      filter: "app",
      likeGroupApp: "no",
    });
    
    const [categoryEdit] = useState(['No Permitidas', 'Sistema', 'Educativas', 'No Educativas', 'Productividad', 'Internet', 'Ocio', 'Otras', 'Sin Asignar']);
    const columns = [
      {
        field: "app",
        header: "Nombre",
      },
      {
        field: "package",
        header: "Package",
      },
    ];

    useEffect(() => {
      loadLazyData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    
    const onReload = (set) => {
      loadLazyData();
      set(false);
    };
    
    useEffect(() => {
      if(reload){
        onReload(setReload);
      }
      // eslint-disable-next-line
    }, [reload]);

    const loadLazyData = async () => {
      let response;
      setLoading(true);

      let json = {
        likeGroupApp: lazyParams.likeGroupApp === 8? null
        : lazyParams.likeGroupApp !== "#" ? lazyParams.likeGroupApp : "no",
        groupApp: lazyParams.filter === "app"? false: lazyParams.filter === "system"? false : true,
        like: globalFilterValue === "" || it.current.value === ""
          ? null
          : globalFilterValue,
        offset: lazyParams.first,
        limit: lazyParams.rows,
        order: lazyParams.sortField === null || lazyParams.sortOrder === null
          ? ["id", "ASC"]
          : lazyParams.sortOrder === 1
          ? [lazyParams.sortField, "ASC"]
          : [lazyParams.sortField, "DESC"],
        column: lazyParams.filter === undefined ? "app" : lazyParams.filter,
      };
    
      response = await get_data("applications", JSON.stringify(json));

      if (response.data?.error && response.data?.error === true) {
        setApps([]);
        setLoading(false);
      } else {
        let data = response.data.rows;
        if (data && data.length === 0) {
          setApps([]);
        } else if (data && data.length !== 0) {
          data.forEach((app) => {
            if (app.app.charCodeAt(0) === 65528 || app.app.charCodeAt(0) === 8291) app.app = "No Disponible";
              app.groupApp = app.groupApp === null? "Sin Asignar" : categorys[app.groupApp]?.name;
          });
          setApps(data);
        }
        setTotalRecords(response.data?.count);
        setLoading(false);
      }
    };

    const onChangeFilterInput = (e) => {
      setGlobalFilterValue(e.target.value);

      if (e.target.value === "") {
        setTimeout(() => {
          loadLazyData();
        }, 1500);
      }
    };

    const onKeyUpInputText = (e) => {
      if (e.key === "Enter") {
        loadLazyData();
      }
    };

    const onFilters = (e) => {
      let filter = e.value;

      if(filter === "groupApp"){
        $("#filter-selector").addClass("notVisible");
        $("#category-selector").addClass("visible");
      }

      if(filter === "app"){
        $("#filter-selector").removeClass("notVisible");
        $("#category-selector").removeClass("visible");
      }

      if(filter === "system"){
        $("#filter-selector").addClass("notVisible");
        $("#category-selector").removeClass("visible");
      }

      setLazyParams({
        ...lazyParams,
        filter: e.value,
        likeGroupApp: "no",
      });
      setSelectedFilter(e.value);
      setCategory(null);
    };

    const onCategorySelect = (e) => {
      console.log(e.value);
      setLazyParams({
        ...lazyParams,
        likeGroupApp: e.value,
      });
      setCategory(e.value);
    }

    const onGlobalFilter = () => {
      loadLazyData();
    };

    const onPage = (event) => {
      setLazyParams({
        ...lazyParams,
        page: event.page,
        first: event.first,
      });
    };

    const onSort = (event) => {
      setLazyParams({
        ...lazyParams,
        sortField: event.sortField,
        sortOrder: event.sortOrder,
      });
    };

    const renderHeader = () => {
      const dropdownOptions = [
        { label: "Nombre", value: "app" },
        { label: "Categoría", value: "groupApp" },
        { label: "Sistema", value: "system" }
      ];
   
      return (
        <div className="containerHeader">
          <div
            className="searchContainer"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "0.5rem",
            }}
          >
            <div className="search-container" id="filter-selector">
              <i className="pi pi-search search" onClick={onGlobalFilter} />
              <InputText
                value={globalFilterValue}
                onChange={onChangeFilterInput}
                placeholder="Buscar..."
                onKeyUp={onKeyUpInputText}
                className="p-input"
                ref={it}
              />
            </div>
            <div className="category-selector-container notVisible" id="category-selector">
              <Dropdown
                value={category}
                options={categorys}
                onChange={onCategorySelect}
                optionLabel="name"
                optionValue="id"
                placeholder="Seleccione categoria"
                className="filterDropdown"
              />
            </div>
            <Dropdown
              value={selectedFilter}
              options={dropdownOptions}
              onChange={onFilters}
              optionLabel="label"
              optionValue="value"
              className="filterDropdown"
            />
          </div>
        </div>
      );
    };
    
    let tableHeader = renderHeader();

    const rowsPerPageDropdown = () => {
      const dropdownOptions = [
        { label: "5", value: 5 },
        { label: "10", value: 10 },
        { label: "20", value: 20 },
      ];
    
      const onRowsPerPageChange = (e) => {
        setSelectedRowsPerPage(e.value);
        setLazyParams({
          ...lazyParams,
          rows: e.value,
        });
      };
    
      return (
        <div className="footerDataTable">
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Aplicaciones por página:
          </span>
          <Dropdown
            value={selectedRowsPerPage}
            options={dropdownOptions}
            onChange={onRowsPerPageChange}
            optionLabel="label"
            optionValue="value"
            className="rowsDropdown"
          />
          <p style={{ marginBottom: "0", fontWeight: "normal" }}>
            {lazyParams.rows > apps.length
              ? `Mostrando ${apps.length} de ${totalRecords}`
              : `Mostrando ${lazyParams.first + 1} a ${
                lazyParams.first + selectedRowsPerPage
            } de ${totalRecords}`}
          </p>
        </div>
      );
    };

    const onRowEditComplete = (e) => {
      let _apps = [...apps];
      const appFound = (element) => element.id === newData.id;
      // console.log(_apps, e);
      let newData = e.newData;
      let index = _apps.findIndex(appFound);
      _apps[index] = newData; 
      let app = _apps[index];
      getApp(app);
      setApps(_apps);
    };

    const categoryBodyTemplate = (rowData) => {
      return <Tag value={rowData.groupApp} category={getCategory(rowData.groupApp)}></Tag>;
    };

    const categoryEditor = (options) => {
      return (
          <Dropdown
              value={options.value}
              options={categoryEdit}
              onChange={(e) => options.editorCallback(e.value)}
              placeholder="Selecciona categoría"
              itemTemplate={(option) => {
                  return <Tag value={option} severity={getCategory(option)}></Tag>;
              }}
          />
      );
    };

    return(
      <div className="datatable-doc-demo">
        <DataTable
            editMode="row"
            size="large"
            className="p-datatable-customers"
            ref={dt}
            value={apps}
            lazy
            dataKey="id"
            paginator
            onPage={onPage}
            onSort={onSort}
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            rows={lazyParams.rows}
            first={lazyParams.first}
            responsiveLayout="scroll"
            totalRecords={totalRecords}
            loading={loading}
            emptyMessage="No existen datos para mostrar."
            header={tableHeader}
            footer={rowsPerPageDropdown}
            onRowEditComplete={onRowEditComplete}
        >
          {columns.map((column) => {
            return (
              <Column
                field={column.field}
                header={column.header}
                sortable
                style={{ textAlign: "center" }}
              />
            );
          })}
          <Column
            field="groupApp"
            header="Categoría"
            sortable
            style={{ textAlign: "center" }}
            body={categoryBodyTemplate} 
            editor={(options) => categoryEditor(options)}
          />
          <Column 
            rowEditor 
            headerStyle={{ width: '10%', minWidth: '8rem' }} 
            bodyStyle={{ textAlign: 'center' }}>
          </Column>
        </DataTable>
      </div>
    )
}