export function elementsLocked(data, tracking) {
  return [
    {
      key: "keyTouch",
      name: "keyTouch",
      label: "Bloqueo de tactil",
      value: data.touch,
      id: "touch",
      type: "checkbox",
    },
    {
      key: "keyScreen",
      name: "keyScreen",
      label: "Bloqueo de pantalla",
      value: data.screen,
      id: "screen",
      type: "checkbox",
    },
    {
      key: "keyBackground",
      name: "keyBackground",
      label: "Cambiar fondo de pantalla",
      value: data.background,
      id: "background",
      type: "checkbox",
    },
    {
      key: "keyAlarm",
      name: "keyAlarm",
      label: "Activar alarma",
      value: data.alarm,
      id: "alarm",
      type: "checkbox",
    },
    {
      key: "keyStatusMessage",
      name: "keyStatusMessage",
      label: "Enviar mensaje",
      value: data.statusMessage,
      id: "statusMessage",
      type: "checkbox",
    },
    {
      key: "keyMessageTitle",
      name: "keyMessageTitle",
      label: "Titulo de mensaje",
      display: data.statusMessage,
      value: data.messageTitle,
      id: "messageTitle",
      type: "text",
    },
    {
      key: "keyMessageBody",
      name: "keyMessageBody",
      label: "Cuerpo de mensaje",
      display: data.statusMessage,
      value: data.messageBody,
      id: "messageBody",
      type: "area",
    },
    {
      key: "keyTracking",
      name: "keyTracking",
      label: "Activar Tracking",
      value: tracking.statusTracking,
      id: "statusTracking",
      type: "checkbox",
    },
    {
      key: "keyTrackingTrack",
      name: "keyTrackingTrack",
      display: tracking.statusTracking,
      label: "Toma de datos",
      small: "(en minutos)",
      value: tracking.tracking,
      id: "tracking",
      type: "number",
    },
    {
      key: "keyReportTracking",
      name: "keyReportTracking",
      display: tracking.statusTracking,
      label: "Envio de datos",
      small: "(en minutos)",
      value: tracking.report,
      id: "report",
      type: "number",
    },
    {
      key: "keyPhotoStatus",
      name: "keyPhotoStatus",
      display: tracking.statusTracking,
      label: "Generar imagenes",
      value: data.photo.status,
      id: "status",
      type: "checkbox",
    },
    {
      key: "keyPhotoTime",
      name: "keyPhotoTime",
      display:
        tracking.statusTracking === true
          ? data.photo.status === true
            ? true
            : false
          : false,
      label: "Intervalo de imagenes",
      small: "(en minutos)",
      value: data.photo.time,
      id: "time",
      type: "number",
    },
    {
      key: "keyPhotoQuality",
      name: "keyPhotoQuality",
      display:
        tracking.statusTracking === true
          ? data.photo.status === true
            ? true
            : false
          : false,
      label: "Resolucion de imagenes: ",
      value: data.photo.quality,
      id: "quality",
      type: "options",
      default: "Seleccione la calidad de imagen",
      options: [
        {
          value: "high",
          title: "Alto",
        },
        {
          value: "mid",
          title: "Medio",
        },
        {
          value: "low",
          title: "Bajo",
        },
      ],
    }
    // ,
    // {
    //   key: "keyRecursive",
    //   name: "keyRecursive",
    //   display: data.statusMessage,
    //   label: "Recursivo",
    //   value: data.recursive,
    //   id: "recursive",
    //   type: "checkbox",
    // },
  ];
}
