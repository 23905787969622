import { Button } from "primereact/button";
import { NavLink } from "react-router-dom";
import "../../../css/generals/dataTable.css";

export default function ActionDashboard(props) {
  return (
    <>
      <NavLink
        to={`/mdm/${props.type}/${props.id}`}
        className="nav-link hoverly"
        state={props?.data}
      >
        <Button
          icon="pi pi-ellipsis-h"
          className="btn-white p-button-rounded"
          aria-label="Search"
        />
      </NavLink>
    </>
  );
}
