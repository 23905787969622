import React from "react";
import "../css/generals/footer.css";

export default function Footer() {
  return (
    <footer className="main-footer">
      <strong className="rights">
        Copyright © 2014-2022{" "}
        <a
          href="https://pixartargentina.com.ar/"
          target="_blank"
          rel="noreferrer"
        >
          Pixart S.R.L.
        </a>{" "}
        Derechos reservados.
      </strong>
    </footer>
  );
}
